<script lang="ts">
  import EventsScreen from "../screens/EventsScreen.svelte";
  import Header from "../components/Header.svelte";
  import FaceBookPixel from "../components/FaceBookPixel.svelte";
  import TicketsScreen from "../screens/TicketsScreen.svelte";
  import { QueryClient, QueryClientProvider } from "@tanstack/svelte-query";
  import RegisterScreen from "../screens/RegisterScreen.svelte";
  import PaymentScreen from "../screens/PaymentScreen.svelte";
  import { params } from "../store/params";
  import { page, removePage, type Page, addPage } from "../store/navigation";
  import Footer from "../components/Footer.svelte";
  import PaymentSuccessScreen from "../screens/PaymentSuccessScreen.svelte";
  import "../i18n";
  import { onDestroy, onMount } from "svelte";
  import PaymentFailScreen from "../screens/PaymentFailScreen.svelte";
  import {
    channelSlug,
    couponCode,
    currentOrder,
    magic,
    orderId,
    pendingPayment,
    selectedEventId,
    trackingSlug,
  } from "../store/global";
  import Hotjar from "../components/Hotjar.svelte";
  import { ordersApiV1 } from "../api/instances";
  import { queryChannelFromTracking } from "../api/queryOptions";
  import NotFoundScreen from "../screens/NotFoundScreen.svelte";

  const queryClient = new QueryClient();

  // State
  let currentPage: Page | undefined;
  let container: HTMLElement;

  // Params
  export let primary: string | undefined;
  export let location: string | undefined;
  export let tos: string | undefined;
  export let business: string | undefined;
  export let pixel: string | undefined;
  export let channel: string | undefined;
  export let tracking: string | undefined;
  export let noScroll: string | undefined;

  const unsubscribe = page.subscribe((value) => {
    currentPage = value.at(-1);
    if (container && !noScroll) {
      container.scrollIntoView({
        behavior: "smooth",
      });
    }
  });

  params.set({
    primary,
    location,
    tos,
    business,
    pixel,
    channel,
    tracking,
    noScroll,
  });

  onMount(async () => {
    const href = window.location.href;
    const url = new URL(href);
    const params = url.searchParams;
    const eventId = params.get("eventId");
    const couponCodeParam = params.get("couponCode");
    const paymentParam = params.get("redirect_status");
    const channelParam = params.get("c");
    const trackingParam = params.get("t");
    const affiliate = params.get("affiliateCode");
    const code = params.get("code");
    const oldChannel = params.get("channel");
    const eventIdInt = eventId ? parseInt(eventId, 10) : NaN;

    // Log current verison
    console.log(`Celebratix widget v${import.meta.env.PACKAGE_VERSION}`);

    // Reset current order
    currentOrder.set(undefined);

    // Set coupon code
    couponCode.set(couponCodeParam);

    if (paymentParam === "succeeded") {
      const orderIdParam = params.get("orderId");
      const eventIdParam = params.get("eventId");
      const magicParam = params.get("magic");
      // Set orderId, eventId and magic if present to allow linking to order
      // and event from the success screen (requires redirect payment method)
      if (magicParam) {
        magic.set(magicParam);
      }
      if (orderIdParam) {
        orderId.set(orderIdParam);
      }
      if (eventIdParam) {
        selectedEventId.set(parseInt(eventIdParam, 10));
      }
      addPage({ page: "PaymentSuccessScreen", title: "payment", index: 4 });
    } else if (paymentParam === "failed") {
      addPage({ page: "PaymentFailScreen", title: "payment", index: 4 });
    } else {
      // Set channel slug
      // If both channel and tracking are present, channel takes precedence
      if (channelParam || oldChannel) {
        channelSlug.set(channelParam ?? oldChannel);
      }

      // Set tracking slug
      if (trackingParam || affiliate || code || tracking) {
        trackingSlug.set(trackingParam ?? affiliate ?? code);
        try {
          const data = await queryClient.fetchQuery({
            ...queryChannelFromTracking(
              trackingParam ?? affiliate ?? code ?? tracking ?? null,
            ),
            queryKey: [
              "channel",
              { slug: trackingParam ?? affiliate ?? code ?? tracking ?? null },
            ],
          });
          const channelSlugData = data.data?.channelSlug;
          if (channelSlugData) {
            // Set channel slug from tracking
            channelSlug.set(channelSlugData);
          }
        } catch (error) {
          console.error(error);
          addPage({ page: "NotFoundScreen", title: "events", index: 0 });
          return;
        }
      }

      // Navigate to specific event or default events screen
      if (!isNaN(eventIdInt)) {
        addPage({ page: "TicketsScreen", title: "tickets", index: 2 });
        selectedEventId.set(eventIdInt);
      } else {
        addPage({
          page: "EventsScreen",
          title: "events",
          index: 1,
        });
      }
    }
  });

  const beforeUnload = async () => {
    if ($pendingPayment) return;
    if (!$currentOrder) return;

    ordersApiV1.postUserOrdersByOrderIdCancel({
      orderId: $currentOrder.orderId,
    });
  };

  // Listen to popstate event and remove/add pages accordingly
  const popState = (event: PopStateEvent) => {
    const currentPage = $page.at(-1);
    if (!currentPage) return;

    if (event.state?.index <= currentPage?.index) {
      if (currentPage.index !== 1) removePage();
    } else if (event.state?.index > currentPage.index) {
      addPage(event.state);
    } else {
      removePage();
    }

    // Add default page if no page is present
    if ($page.length === 0) {
      addPage({ page: "EventsScreen", title: "events", index: 1 });
    }
  };

  onDestroy(unsubscribe);
</script>

<FaceBookPixel />
<Hotjar />

<!-- Cancel order on unload -->
<svelte:window on:beforeunload={beforeUnload} on:popstate={popState} />

<QueryClientProvider client={queryClient}>
  <div bind:this={container} class="celebratix-widget-root">
    <Header />
    <div class="celebratix-widget-container">
      {#if currentPage?.page === "EventsScreen"}
        <EventsScreen />
      {:else if currentPage?.page === "TicketsScreen"}
        <TicketsScreen />
      {:else if currentPage?.page === "RegisterScreen"}
        <RegisterScreen />
      {:else if currentPage?.page === "PaymentScreen"}
        <PaymentScreen />
      {:else if currentPage?.page === "PaymentSuccessScreen"}
        <PaymentSuccessScreen />
      {:else if currentPage?.page === "PaymentFailScreen"}
        <PaymentFailScreen />
      {:else if currentPage?.page === "NotFoundScreen"}
        <NotFoundScreen />
      {/if}
    </div>
    <Footer />
  </div>
</QueryClientProvider>

<style>
  @font-face {
    font-family: "SupremeLLTT-Regular";
    font-style: normal;
    font-weight: 400;
    src: url("__CSS_CDN_URL/fonts/SupremeLLTT-Regular.ttf") format("truetype");
  }
  @font-face {
    font-family: "SupremeLLTT-Light";
    font-style: normal;
    font-weight: 300;
    src: url("__CSS_CDN_URL/fonts/SupremeLLTT-Light.ttf") format("truetype");
  }
  :global(select) {
    all: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 52px;
    padding: 0px 16px 0px 16px;
    border: 1px solid #78716c;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: transparent;
    color: #fafaf9;
    cursor: pointer;
    /* Arrow */
    background-image: url("__CSS_CDN_URL/icons/chevron-down.svg");
    background-position: right 16px center;
    background-repeat: no-repeat;
    background-size: 16px;
    padding-right: 32px;
  }
  :global(option) {
    background-color: #000;
  }
  :global(select:focus) {
    border: 1px solid #fafaf9;
  }
  :global(select:hover) {
    border: 1px solid #fafaf9;
  }
  /* Apply base style to all input classes */
  /* Mitigates some style overrides that client's may have */
  :global(input),
  :global(input[type="email"]),
  :global(input[type="radio"]),
  :global(input[type="checkbox"]),
  :global(input[type="date"]),
  :global(input[type="text"]) {
    all: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 52px;
    width: 100%;
    padding: 0px 16px 0px 16px;
    border: 1px solid #78716c;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: transparent;
    color: #fafaf9;
  }
  :global(input::placeholder) {
    color: #a8a29e;
  }
  :global(input:focus) {
    border: 1px solid #fafaf9;
  }
  :global(input:hover) {
    border: 1px solid #fafaf9;
  }
  :global(input[type="radio"]) {
    box-sizing: border-box;
    height: 20px;
    width: 20px;
    padding: 0;
    border: 1px solid #78716c;
    border-radius: 50%;
    display: grid;
    place-content: center;
    cursor: pointer;
  }
  :global(input[type="radio"]::before) {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    color: #a3f3ef;
    background-color: #a3f3ef;
  }
  :global(input[type="radio"]:checked::before) {
    transform: scale(1);
  }
  :global(input[type="radio"]:checked) {
    border: 1px solid #a3f3ef;
  }
  :global(input[type="checkbox"]) {
    display: grid;
    place-content: center;
    box-sizing: border-box;
    height: 20px;
    width: 20px;
    padding: 0;
    border: 1px solid #78716c;
    border-radius: 4px;
    cursor: pointer;
  }
  :global(input[type="checkbox"]:checked) {
    background-color: #a3f3ef;
    background-image: url("__CSS_CDN_URL/icons/check.svg");
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid #a3f3ef;
  }
  :global(input[type="email"]) {
    background-image: url("__CSS_CDN_URL/icons/envelope.svg");
    background-position: 16px center;
    background-repeat: no-repeat;
    background-size: 24px;
    padding-left: 48px;
  }
  :global(input[type="date"]) {
    display: inline;
  }
  :global(input[type="date"]::-webkit-calendar-picker-indicator) {
    background-image: url("__CSS_CDN_URL/icons/calendar-days.svg");
    background-repeat: no-repeat;
    background-size: 24px;
    height: 24px;
    width: 24px;
  }
  :global(button) {
    all: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    width: 100%;
    background-color: #8bf3ee;
    color: #1c1917;
    cursor: pointer;
    border-radius: 4px;
  }
  :global(button:hover) {
    background-color: #7ab6b3;
    color: #1c1917;
  }
  :global(button:disabled) {
    background-color: #191919;
    color: #57534e;
    cursor: auto;
  }
  :global(.celebratix-widget-h1) {
    font-family: SupremeLLTT-Regular;
    font-size: 80px;
    line-height: 1;
  }
  :global(.celebratix-widget-h2) {
    font-family: SupremeLLTT-Regular;
    font-size: 46px;
    line-height: 1;
  }
  :global(.celebratix-widget-h3) {
    font-family: SupremeLLTT-Regular;
    font-size: 24px;
    line-height: 1.2;
  }
  :global(.celebratix-widget-h4) {
    font-family: SupremeLLTT-Regular;
    font-size: 20px;
    line-height: 1.2;
  }
  :global(.celebratix-widget-h5) {
    font-family: SupremeLLTT-Regular;
    font-size: 18px;
    line-height: 1.3;
  }
  :global(.celebratix-widget-text-l) {
    font-family: SupremeLLTT-Light;
    font-size: 18px;
    line-height: 1.4;
  }
  :global(.celebratix-widget-text-l-bold) {
    font-family: SupremeLLTT-Regular;
    font-size: 18px;
    line-height: 1.4;
  }
  :global(.celebratix-widget-text-base) {
    font-family: SupremeLLTT-Light;
    font-size: 15px;
    line-height: 1.4;
  }
  :global(.celebratix-widget-text-base-bold) {
    font-family: SupremeLLTT-Regular;
    font-size: 15px;
    line-height: 1.4;
  }
  :global(.celebratix-widget-text-s) {
    font-family: SupremeLLTT-Light;
    font-size: 13px;
    line-height: 1.5;
  }
  :global(.celebratix-widget-text-s-bold) {
    font-family: SupremeLLTT-Regular;
    font-size: 13px;
    line-height: 1.5;
  }
  :global(.celebratix-widget-text-xs) {
    font-family: SupremeLLTT-Light;
    font-size: 11px;
    line-height: 1.5;
  }
  :global(.celebratix-widget-text-xs-bold) {
    font-family: SupremeLLTT-Regular;
    font-size: 11px;
    line-height: 1.5;
  }
  :global(.celebratix-widget-link) {
    all: unset;
    text-decoration: underline;
    cursor: pointer;
  }
  .celebratix-widget-root {
    color: #fafaf9;
    position: relative;
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .celebratix-widget-container {
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    max-width: 640px;
  }
</style>
